import styles from './Modal.module.scss'
import {useEffect, useMemo, useRef, useState} from 'react'
import {motion} from 'framer-motion'

interface ModalProps {
  opened: boolean;
  onClose: () => void;
}

export default function Modal({opened, onClose}: ModalProps) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const inputHasError = (name: string, value: string) => {
    switch(name) {
      case 'name':
        if(value.length < 2) return true
        return false
      break
      case 'email':
        if(!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g.test(value)) return true
        return false
      break
      default:
        return false
    }
  }

  const handleErrors = (name: string, value: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: inputHasError(name, value),
    }));
  };
  const handleBlur = (e: any) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: inputHasError(name, value),
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if(!opened || response) return
    const botId = "7494965293:AAHLhnPrxiR-756CvUa2GAlEU1tnUDh08Rw"
    const chatId = "5112461814"

    setIsSubmitting(true);

    try {

      let message = '*NEW APPLICATION🔥*\n\n'
      message += `*name:* ${formData.name}\n`
      message += `*email:* ${formData.email}\n`
      message += `*message:* ${formData.message}\n\n`
      message += `_Process faster⏳_`

      for(const [name, value] of Object.entries(formData)) {
        handleErrors(name, value)
      }

      for(const [key, value] of Object.entries(errors)) {
        if(value === true) throw new Error('Some inputs has errors')
      }
      
      const response = await fetch(`https://api.telegram.org/bot${botId}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(message)}&parse_mode=markdown`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const result = await response.json();
      setResponse(result);
    } catch (error) {
      // console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }


  };

  // just keyboard
  const inputRefs = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null)]
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    window.addEventListener('keyup', (e: any) => {
      switch(e.code) {
        case 'Escape':
          onClose()
        break
      }
    })
  }, [])


  // Good inputs
  const handleFocus = (index: number) => (e: any) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (opened && inputRefs[activeIndex].current) {
      inputRefs[activeIndex].current.focus();
    }
  }, [activeIndex, inputRefs]);
  // Обработчик события onFocus

  const handleKeyDown = (e: any) => {
    if (e.key === 'ArrowDown' || e.key === 'Tab') {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
    } else if (e.key === 'ArrowUp') {
      setActiveIndex((prevIndex) => (prevIndex + 2) % 3);
    }
  }

  return (
    <motion.div className={styles.modal + " " + (opened ? styles.modalOpened : "")}>
      <div onClick={() => {onClose()}} className={styles.modalClose}>close</div>
      <div className={styles.modalInner}>
      <h2 className={styles.modalTitle1}>Get <br/> in <br/> touch</h2>
      <form onSubmit={handleSubmit} onKeyDown={handleKeyDown} className={styles.modalForm}>
        <input
          ref={inputRefs[0]}
          placeholder="your name"
          className={styles.modalInput + " " + (errors.name === true ? styles.modalInputError : "")}
          type="text"
          id="name"
          name="name"
          value={formData.name}
          disabled={response}
          onChange={handleChange}
          onBlur={handleBlur}
          onClick={handleFocus(0)}
        />
        <input
          ref={inputRefs[1]}
          placeholder="email"
          className={styles.modalInput + " " + (errors.email === true ? styles.modalInputError : "")}
          type="email"
          disabled={response}
          id="email"
          name="email"
          // formNoValidate
          value={formData.email}
          onChange={handleChange}
          onBlur={handleBlur}
          onClick={handleFocus(1)}
        />
        <textarea
          ref={inputRefs[2]}
          placeholder="message"
          style={{resize: "none"}}
          className={styles.modalTextarea + " " + (errors.message === true ? styles.modalInputError : "")}
          id="message"
          name="message"
          disabled={response}
          value={formData.message}
          onChange={handleChange}
          onBlur={handleBlur}
          onClick={handleFocus(2)}
        />
        <button className={styles.modalButton} disabled={response ? true : false}  style={{backgroundColor: response ? "#bababa" : ""}}>
          {!response && !isSubmitting ?'Get in touch': ''}
          {isSubmitting && !response ? 'Submitting...' : ''}
          {response && !isSubmitting ? 'Done...': ''}
        </button>
      </form>
      {response && <h2 className={styles.modalTitle2}>Thank <br/> you</h2>}
      </div>
    </motion.div>
  )
}