import React, { useState } from 'react'
import styles from './layout.module.scss'
import logo from '../assets/images/logo-new.svg'
import mouse from '../assets/images/mouse.svg'
import { useEffect, useRef } from 'react'
import Cursor from './Cursor'
import music from '../assets/music/website-music.mp3'
import { AnimatePresence, motion } from "framer-motion"

import adv1 from '../assets/images/adv1.svg'
import adv2 from '../assets/images/adv2.svg'
import adv3 from '../assets/images/adv3.svg'
import adv4 from '../assets/images/adv4.svg'

import Modal from './Modal'

export default function Layout() {
  const [direction, setDirection] = useState('down')
  const layoutRef: any = useRef(null)
  const [isMobile, setIsMobile] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  
  // audio
  const [audioPlaying, setAudioPlaying] = useState(false)

  const audioRef: any = useRef(null);
  if(audioRef.current) {
    audioRef.current.volume = 0.1;
    audioRef.current.onended = () => {
      audioRef.current.play()
    }
  }
  
  let toggleAudio = () => {
    if(audioRef.current) {
      if(audioPlaying) {
        audioRef.current.pause()
        setAudioPlaying(false)
      } else {
        audioRef.current.currentTime = 0
        audioRef.current.play()
        setAudioPlaying(true)
      }
    }
  }

  // changing steps
  const [step, setStep] = useState(1)
  const [stepChanging, setStepChanging] = useState(false)
  const [nativeScrollingStatus, setNativeScrollingStatus] = useState(false)
  
  const contentArea = useRef<HTMLDivElement>(null)
  const buttonElem = useRef<HTMLButtonElement>(null)

  const stepForward = () => {
    setStep(prevState => {
      if(prevState+1 > 3) return prevState
      else return prevState+1
    })
  }
  const stepBackward = () => {
    setStep(prevState => {
      if(prevState-1 === 0) return prevState
      else return prevState-1
    })
  }

  useEffect(() => {
    if(isMobile) return
    const wheelStepChange = (e: any) => {
      if(isMobile || modalOpened) return
      if(stepChanging === true || nativeScrollingStatus === true) return
      setStepChanging(true)

      const delta = e.deltaY

      if(delta > 0) {
        if(step === 2 && direction === 'down') stepForward()
        else stepForward()
      }
      else if(delta < 0) {
        if(step === 2 && direction === 'up') stepBackward()
        stepBackward()
      }

      setTimeout(() => {setStepChanging(false)}, 1600)
    }
    window.addEventListener('wheel', wheelStepChange)
    return () => window.removeEventListener('wheel', wheelStepChange)
  }, [stepChanging, nativeScrollingStatus, modalOpened, isMobile])

  // Enter on mouse
  useEffect(() => {
    if(isMobile) return
    const enterIntro = (e: any) => {
      if(e.code === "Enter" && step === 1 && stepChanging === false) {
        startIntro()
      }
    }

    window.addEventListener('keyup', enterIntro)
    return () => window.removeEventListener('keyup', enterIntro)
  }, [stepChanging, nativeScrollingStatus, isMobile])

  const startIntro = () => {
    if(step === 1) {
      setDirection('down')
      stepForward()
      if(!audioPlaying) toggleAudio()
    }
  }

  // step2 scrolling
  const part2ListRef: any = useRef()
  const [part2ElemActive, setPart2ElemActive] = useState(1)

  useEffect(() => {
    if(isMobile) return
    const wheelPartChange = (e: any) => {
        if(modalOpened) return
        if(part2ListRef.current) {
          const $list = part2ListRef.current
          if($list) $list.scrollTop += e.deltaY/16

          let scrollPercentage = ($list.scrollTop / ($list.scrollHeight - $list.clientHeight)) * 100;
          const elemActive = Math.round(1 + (scrollPercentage / 85) * (5 - 1))
          setPart2ElemActive(elemActive)



          if($list.scrollTop === 0 && direction === 'up') {
            setNativeScrollingStatus(false)
          } else if($list.scrollTop+$list.clientHeight > $list.scrollHeight - 1 && direction === 'down') {
            setNativeScrollingStatus(false)
          } else {
            setNativeScrollingStatus(true)
          }
        }
    }

    if(step === 2) {
      setNativeScrollingStatus(true)
      window.addEventListener('wheel', wheelPartChange)
      return () => window.removeEventListener('wheel', wheelPartChange)
    }
  }, [step, direction, modalOpened, isMobile])

  // loader
  useEffect(() => {
    // Loader animations
    setTimeout(() => layoutRef.current.classList.add(styles.loader1), 1000)
    setTimeout(() => layoutRef.current.classList.add(styles.loader2), 2000)
    setTimeout(() => layoutRef.current.classList.add(styles.loader3), 3000)
    setTimeout(() => layoutRef.current.classList.add(styles.loader4), 4000)
    setTimeout(() => {
      layoutRef.current.classList.add(styles.loader5)
    }, 5000)
  }, [])



  // direction status

  useEffect(() => {
    if(isMobile) return
    const wheelDirectionChange = (e: any) => {
      // if(stepChanging===true) return
      // setStepChanging(true)

      const delta = e.deltaY
      setDirection(delta > 0 ? 'down' : 'up')
    }
    window.addEventListener('wheel', wheelDirectionChange)
  }, [direction, isMobile])

  //button width
  useEffect(() => {
    if(isMobile) return
    if(contentArea.current && buttonElem.current) {
      if(step === 2) {
        buttonElem.current.style.width = contentArea.current.clientWidth + 'px'
      } else if(step === 3) {
        buttonElem.current.style.width = '100vw'
      }
    }
  }, [step, isMobile])

  useEffect(() => {
    const mobileWidthListener = () => {
      setIsMobile(window.innerWidth <= 900)
    }
    mobileWidthListener()
    window.addEventListener('resize', mobileWidthListener)
  }, [])

  return (
    <>
    <audio ref={audioRef} src={music}></audio>
    <Cursor />
    <div className={styles.noise}></div>
    <motion.div ref={layoutRef} className={styles.layout}>
      <motion.button onClick={() => {setModalOpened(true)}} className={`${styles.button} ${step !== 1 ? styles.active : ""}`}
          ref={buttonElem}
      >Get in touch</motion.button>
      <div className={styles.title1}><h2 style={step > 1 ? {opacity: .2} : {}} className={styles.title1Text}>From concept</h2></div>
      <a className={styles.logo} href=""><img draggable="false" className={styles.logoImage} src={logo} alt="" /></a>
      <div className={styles.title2}><h2 style={step > 1 ? {opacity: .2} : {}} className={styles.title2Text}>To reality</h2></div>
      {isMobile &&
        <p className={styles.contentHeadersDescription}>Empowering your brand to thrive globally through strategic digital marketing and seamless multi-channel engagement</p>
      }


      {!isMobile &&
      <div className={styles.contentHeaders + " " + styles[step === 3 ? 'contentHeadersMinify' : '']}>
        <motion.div className={styles.contentHeadersInner}>
          <AnimatePresence mode="wait">
            {step === 1 &&
              <motion.p key="contentHeader1" transition={{ duration: 1 }} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className={styles.contentHeadersDescription}>Empowering your brand to thrive globally through strategic digital marketing and seamless multi-channel engagement</motion.p>
            }
            {step === 2 &&
              <motion.h3 key="contentHeader2" transition={{ duration: 1 }} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}  className={styles.contentHeadersStep2}>WE</motion.h3>
            }
            {step === 3 &&
              <motion.h3 key="contentHeader3" transition={{ duration: 1 }} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}  className={styles.contentHeadersStep3}>Why choose RSG as your partner?</motion.h3>
            }
          </AnimatePresence>
        </motion.div>
      </div>
      }
      {!isMobile &&
      <div ref={contentArea} onClick={startIntro} className={[styles.contentArea, styles[['step',step].join('')]].join(' ')}>
      <motion.div className={styles.contentAreaInner}>
        <AnimatePresence mode="wait">
        {step===1 &&
        <motion.div
        key="step1_content"
        initial={{ opacity: 0, y: -50}}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: .6 }}
        >
          <div className={styles.contentAreaMouseWrapper}>
          <img className={styles.contentAreaMouse} draggable="false" src={mouse} alt="" />  
          </div>
        </motion.div>}

        {step===2 && 
        <motion.div className={styles.contentAreaListWrapper}
        key="step2_content"
        initial={{ opacity: 0, y: direction === 'down' ? 50 : -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: direction === 'down' ? -50 : 50 }}
        transition={{ duration: .6 }}
        >
          <ul ref={part2ListRef} className={styles.contentAreaList}>
            <li style={part2ElemActive === 1 ? {color: '#fff'} : {}}>offer multifaceted strategies aimed to target, reach out to, and build relationships with customers online, across a broad range of channels</li>
            <li style={part2ElemActive === 2 ? {color: '#fff'} : {}}>start from defining your Target Audience and what they're looking for</li>
            <li style={part2ElemActive === 3 ? {color: '#fff'} : {}}>help to choose the right platform and the most effective ad format to reach your Target Audience</li>
            <li style={part2ElemActive === 4 ? {color: '#fff'} : {}}>use various Ad Placement Techniques to maximize the effectiveness of your campaigns</li>
            <li style={part2ElemActive === 5 ? {color: '#fff'} : {}}>provide digital marketing services and IT consulting for the placement of advertising and information materials to increase your brand awareness and improve user acquisition by establishing synergy between various platforms all over the world</li>
          </ul>
        </motion.div>
        }
        {step===3 && 
        <motion.div className={styles.contentAreaExpertise}
        key="step3_content"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: .6 }}
        >
            <ul className={styles.contentAreaExpertiseList}>
              <li><img src={adv1} alt="" /><span>Industry expertise</span></li>
              <li><img src={adv2} alt="" /><span>Comprehensive Digital <br/> Marketing Solutions</span></li>
              <li><img src={adv3} alt="" /><span>Results-Driven Approach</span></li>
              <li><img src={adv4} alt="" /><span>Exceptional Client Service</span></li>
            </ul>
        </motion.div>
        }
        </AnimatePresence>
      </motion.div>
    </div>
      }

      {isMobile &&
      <div className={[styles.contentArea, styles[['step',step].join('')]].join(' ')}>
      <div className={styles.contentAreaInner}>
        <div className={styles.contentAreaMouseWrapper}>
          <img className={styles.contentAreaMouse} draggable="false" src={mouse} alt="" />  
        </div>

        <div className={styles.contentAreaListWrapper}>
          <h3 className={styles.contentHeadersStep2}>WE</h3>
          <ul className={styles.contentAreaList}>
            <li>offer multifaceted strategies aimed to target, reach out to, and build relationships with customers online, across a broad range of channels</li>
            <li>start from defining your Target Audience and what they're looking for</li>
            <li>help to choose the right platform and the most effective ad format to reach your Target Audience</li>
            <li>use various Ad Placement Techniques to maximize the effectiveness of your campaigns</li>
            <li>provide digital marketing services and IT consulting for the placement of advertising and information materials to increase your brand awareness and improve user acquisition by establishing synergy between various platforms all over the world</li>
          </ul>
        </div>
        <h3 className={styles.contentHeadersStep3}>Why choose RSG as your partner?</h3>
        <div className={styles.contentAreaExpertise} key="step3_content">
            <ul className={styles.contentAreaExpertiseList}>
              <li><img src={adv1} alt="" /><span>Industry expertise</span></li>
              <li><img src={adv2} alt="" /><span>Comprehensive Digital <br/> Marketing Solutions</span></li>
              <li><img src={adv3} alt="" /><span>Results-Driven Approach</span></li>
              <li><img src={adv4} alt="" /><span>Exceptional Client Service</span></li>
            </ul>
        </div>
      </div>
    </div>
      }

      <div onClick={toggleAudio} className={styles.footerCompany}>
        {audioPlaying && <div className={styles.footerCompanyMusic}></div>}
        <p className={styles.footerCompanyText}>RS GROUP L.L.C-FZ</p>
        </div>
      <div className={styles.footerAddress}>
        <p className={styles.footerAddressText}>Meydan Grandstand, 6th floor, Meydan Road, Nad Al Sheba, Dubai, U.A.E</p>
        </div>
      <a href="mailto:info@rsgroup.company" className={styles.footerEmail}>
        <p className={styles.footerEmailText}>info@rsgroup.company</p>
      </a>
    </motion.div>
    <AnimatePresence>
      <Modal opened={modalOpened} onClose={() => {setModalOpened(false)}} />
    </AnimatePresence>
    </>
  )
}