import { useEffect, useRef, useState } from 'react'
import styles from './Cursor.module.scss'

export default function Cursor() {
  const cursor = useRef(null)
  const [coords, setCoords] = useState({ left: 0, top: 0, transform: "scale(0)" }); // кладем в стейт данные курсора
  const [coordsInner, setCoordsInner] = useState({ left: 0, top: 0 }); // кладем в стейт данные курсора
  useEffect(() => {

    const mouseListener = (e: any) => {

      setCoords( {left: e.pageX, top: e.pageY, transform: "scale(1)"});
    }

    document.addEventListener('mousemove', mouseListener);

    return () => {
      document.addEventListener('mousemove', mouseListener);
    }

  }, [])

  return (
    <>
    <div ref={cursor} style={coords} className={styles.cursor}>
      <div className={styles.cursorInner}></div>
    </div>
    </>
  )
}